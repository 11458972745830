import { useEffect } from 'react'
import { navigate } from 'gatsby'

const defaultLanguage = 'en'
const supportedLanguages = ['de', 'en']

const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) return defaultLanguage

  const lang =
    navigator && navigator.language && navigator.language.split('-')[0]
  if (!lang) return defaultLanguage

  return supportedLanguages.indexOf(lang) === -1 ? defaultLanguage : lang
}

const IndexPage = () => {
  useEffect(() => {
    const urlLang = getRedirectLanguage()
    navigate(`/${urlLang}`)
  }, [])

  return null
}

export default IndexPage
